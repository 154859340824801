@font-face {
    font-family: "Avenir";
    src: url("./theme/fonts/Avenir-Roman.otf") format("opentype");
}

body {
    margin: 0;
    font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
::before,
::after {
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

img {
    vertical-align: middle;
    border-style: none;
}

