.ant-collapse-header {
    color: white
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    color: white !important
}

#folder-path{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
@primary-color: #0D1C36;@font-family: Avenir, -apple-system, BlinkMacSystemFont, Roboto, Arial;